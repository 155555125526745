import React from 'react';

import Scene from 'app/components/Scene';
import Container from 'app/components/Container';
import Error404Panel from 'app/components/Error404Panel';

const Error404Scene = () => (
    <Scene>
        <Container>
            <Error404Panel />
        </Container>
    </Scene>
);

export default Error404Scene;
