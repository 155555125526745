import React from 'react';

import styles from './Error404Panel.module.scss';

const Error404Panel = () => (
    <div className={styles.root}>
        <h2 className={styles.title}>Page Not Found</h2>
        <p className={styles.description}>
            Sorry, we couldn&#39;t find the page you were looking for.
        </p>
    </div>
);

export default Error404Panel;
